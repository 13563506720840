import axios from 'axios';

const API_URL = '/api';

const instance = axios.create({
    baseURL: API_URL,
});

export const fetchLabels = async () => {
    const response = await instance.get('/labels/');
    return response.data;
};

export const createLabel = async (labelData) => {
    const response = await instance.post('/labels/', labelData);
    return response.data;
};

export const updateLabel = async (labelId, labelData) => {
    const response = await instance.put(`/labels/${labelId}`, labelData);
    return response.data;
};

export const deleteLabel = async (labelId, force = false) => {
    const response = await instance.delete(`/labels/${labelId}`, { params: { force } });
    return response.data;
};

export const createTimeSeries = async (timeSeriesData) => {
    const response = await instance.post('/timeseries/', timeSeriesData);
    return response.data;
};

export const updateTimeSeries = async (timeSeriesId, timeSeriesData) => {
    const response = await instance.put(`/timeseries/${timeSeriesId}`, timeSeriesData);
    return response.data;
};

export const deleteTimeSeries = async (timeSeriesId) => {
    const response = await instance.delete(`/timeseries/${timeSeriesId}`);
    return response.data;
};

export const fetchTimeSeriesByIds = async (ids) => {
    const response = await instance.get('/timeseries/', { params: { ids: ids.join(',') } });
    return response.data;
};

export const fetchDataBySeriesIds = async (seriesIds) => {
    const response = await instance.get('/data/', { params: { ids: seriesIds.join(',') } });
    return response.data;
};

export const updateDataBySeriesIds = async (seriesIds, newData) => {
    const response = await instance.put('/data/', { ids: seriesIds, data: newData });
    return response.data;
};
import React, { useState, useEffect } from 'react';
import { Table, Input, Checkbox } from 'antd';
import { fetchTimeSeriesByIds, updateTimeSeries } from '../services/api';
import { generateUniqueId } from '../utils/uuidGenerator';

const TimeSeriesTable = ({ timeSeriesIds, onCheckTimeSeries, checkedTimeSeries }) => {
    const [timeSeries, setTimeSeries] = useState([]);

    useEffect(() => {
        if (timeSeriesIds.length > 0) {
            fetchTimeSeriesData(timeSeriesIds);
        } else {
            setTimeSeries([]);
        }
    }, [timeSeriesIds]);

    const fetchTimeSeriesData = async (ids) => {
        try {
            const data = await fetchTimeSeriesByIds(ids);
            setTimeSeries(data);
        } catch (error) {
            console.error('Error fetching time series:', error);
        }
    };

    const handleChange = (value, record, dataIndex) => {
        const newData = [...timeSeries];
        const index = newData.findIndex((item) => record.series_id === item.series_id);
        const item = newData[index];
        item[dataIndex] = value;
        // 如果修改的是序列名称，自动更新指标ID
        if (dataIndex === 'series_name') {
            item.indicator_id = generateUniqueId(value);
        }
        setTimeSeries(newData);
        handleSave(item);
    };

    const handleSave = async (record) => {
        try {
            await updateTimeSeries(record.series_id, record);
        } catch (error) {
            console.error('Error updating time series:', error);
        }
    };

    const handleCheck = (checkedValue, record) => {
        const newCheckedTimeSeries = checkedValue
            ? [...checkedTimeSeries, record.series_id]
            : checkedTimeSeries.filter(id => id !== record.series_id);
        onCheckTimeSeries(newCheckedTimeSeries);
    };

    const columns = [
        {
            title: '',
            dataIndex: 'series_id',
            key: 'checkbox',
            render: (series_id) => (
                <Checkbox
                    checked={checkedTimeSeries.includes(series_id)}
                    onChange={(e) => handleCheck(e.target.checked, { series_id })}
                />
            ),
        },
        {
            title: '序列名称',
            dataIndex: 'series_name',
            key: 'series_name',
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleChange(e.target.value, record, 'series_name')}
                />
            ),
        },
        {
            title: '单位',
            dataIndex: 'unit',
            key: 'unit',
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleChange(e.target.value, record, 'unit')}
                />
            ),
        },
        {
            title: '频率',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleChange(e.target.value, record, 'frequency')}
                />
            ),
        },
        {
            title: '指标ID',
            dataIndex: 'indicator_id',
            key: 'indicator_id',
            render: (text) => (
                <Input value={text} disabled />
            ),
        },
    ];

    return (
        <div style={{ marginBottom: '25px' }}> {/* 增加下方间距 */}
            <Table
                columns={columns}
                dataSource={timeSeries}
                rowKey="series_id"
                pagination={false}
            />
        </div>
    );
};

export default TimeSeriesTable;
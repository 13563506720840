import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';
import 'antd/dist/reset.css';
import './index.css';

// 配置 axios 默认设置
axios.defaults.headers.common['Accept'] = 'application/json; charset=utf-8';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
import React, { useState, useEffect } from 'react';
import { Layout, message } from 'antd';
import LabelTree from './components/LabelTree';
import TimeSeriesTable from './components/TimeSeriesTable';
import DataTable from './components/DataTable';
import { fetchDataBySeriesIds, updateDataBySeriesIds } from './services/api';

const { Sider, Content } = Layout;

const App = () => {
    const [selectedTimeSeries, setSelectedTimeSeries] = useState([]);
    const [checkedTimeSeries, setCheckedTimeSeries] = useState([]);
    const [data, setData] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);
    const [needRefetch, setNeedRefetch] = useState(false);

    useEffect(() => {
        if (dataFetched && !isArrayEqual(checkedTimeSeries, data?.series_ids)) {
            setNeedRefetch(true);
            setDataFetched(false);
            message.warning('选中的时间序列已变更，请重新提取数据');
        } else {
            setNeedRefetch(false);
        }
    }, [checkedTimeSeries, dataFetched, data]);

    const isArrayEqual = (arr1, arr2) => {
        if (!arr1 || !arr2) return false;
        return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
    };

    const handleSelectTimeSeries = (timeSeriesIds) => {
        setSelectedTimeSeries(timeSeriesIds);
        setCheckedTimeSeries(timeSeriesIds);
        if (dataFetched && !isArrayEqual(timeSeriesIds, data?.series_ids)) {
            setDataFetched(false);
            setData(null);
            message.warning('选中的时间序列已变更，请重新提取数据');
        }
    };

    const handleCheckTimeSeries = (timeSeriesIds) => {
        setCheckedTimeSeries(timeSeriesIds);
        if (dataFetched && !isArrayEqual(timeSeriesIds, data?.series_ids)) {
            setDataFetched(false);
            setData(null);
            message.warning('选中的时间序列已变更，请重新提取数据');
        }
    };

    const handleDeleteTimeSeries = (deletedIds) => {
        setSelectedTimeSeries(prev => prev.filter(id => !deletedIds.includes(id)));
        setCheckedTimeSeries(prev => prev.filter(id => !deletedIds.includes(id)));
        if (dataFetched) {
            setDataFetched(false);
            setData(null);
            message.warning('部分时间序列已被删除，请重新提取数据');
        }
    };

    const handleFetchData = async () => {
        if (checkedTimeSeries.length === 0) {
            message.warning('请先选择时间序列');
            return;
        }
        try {
            const fetchedData = await fetchDataBySeriesIds(checkedTimeSeries);
            setData({ ...fetchedData, series_ids: checkedTimeSeries });
            setDataFetched(true);
            setNeedRefetch(false);
            message.success('数据提取成功');
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('获取数据失败');
        }
    };

    const handleDataChange = async (newData) => {
        try {
            await updateDataBySeriesIds(checkedTimeSeries, newData);
            message.success('数据更新成功');
            handleFetchData(); // 重新获取更新后的数据
        } catch (error) {
            console.error('Error updating data:', error);
            message.error('更新数据失败');
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={300} theme="light" style={{ borderRight: '1px solid #f0f0f0' }}>
                <LabelTree
                    onSelectTimeSeries={handleSelectTimeSeries}
                    onDeleteTimeSeries={handleDeleteTimeSeries}
                />
            </Sider>
            <Layout>
                <Content style={{ padding: '24px', backgroundColor: '#fff' }}>
                    <TimeSeriesTable
                        timeSeriesIds={selectedTimeSeries}
                        onCheckTimeSeries={handleCheckTimeSeries}
                        checkedTimeSeries={checkedTimeSeries}
                    />
                    <DataTable
                        data={data}
                        onDataChange={handleDataChange}
                        onFetchData={handleFetchData}
                        needRefetch={needRefetch}
                        dataFetched={dataFetched}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};

export default App;
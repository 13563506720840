import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space, message, Upload } from 'antd';
import moment from 'moment';
import * as XLSX from 'xlsx';
import axios from 'axios';

const DataTable = ({ data, onDataChange, onFetchData, needRefetch, dataFetched }) => {
    const [editMode, setEditMode] = useState(false);
    const [localData, setLocalData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        if (data && data.data) {
            const processedData = data.data.map((item, index) => ({
                ...item,
                key: index,
                ...data.series_names.reduce((acc, name) => {
                    acc[name] = item[name] === null ? '' : item[name].toString();
                    return acc;
                }, {})
            }));
            setLocalData(processedData);
            setOriginalData(processedData);
        } else {
            setLocalData([]);
            setOriginalData([]);
        }
        setSelectedRowKeys([]);
        setEditMode(false);
    }, [data]);

    useEffect(() => {
        if (needRefetch) {
            message.warning('选中的时间序列已变更，请重新提取数据');
            setEditMode(false);
            setLocalData([]);
            setOriginalData([]);
        }
    }, [needRefetch]);

    const handleChange = (value, key, column) => {
        const newData = localData.map(item =>
            item.key === key ? { ...item, [column]: value } : item
        );
        setLocalData(newData);
    };

    const columns = [
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                editMode ? (
                    <Input
                        value={text}
                        onChange={(e) => handleChange(e.target.value, record.key, 'date')}
                    />
                ) : (
                    <span>{text}</span>
                )
            ),
        },
        ...(data && data.series_names ? data.series_names.map(name => ({
            title: name,
            dataIndex: name,
            key: name,
            render: (text, record) => (
                editMode ? (
                    <Input
                        value={text}
                        onChange={(e) => handleChange(e.target.value, record.key, name)}
                    />
                ) : (
                    <span>{text}</span>
                )
            ),
        })) : []),
    ];

    const handleInsertRow = () => {
        const newRow = {
            key: localData.length,
            date: moment().format('YYYY-MM-DD'),
            ...data.series_names.reduce((acc, name) => ({ ...acc, [name]: '' }), {}),
        };
        setLocalData([newRow, ...localData]);
    };

    const handleDeleteSelectedRows = () => {
        const newData = localData.filter((item) => !selectedRowKeys.includes(item.key));
        setLocalData(newData);
        setSelectedRowKeys([]);
    };

    const handleUndoChanges = () => {
        setLocalData(originalData);
        setSelectedRowKeys([]);
    };

    const parseNumber = (value) => {
        if (typeof value === 'string') {
            // 移除千位分隔符（逗号）
            value = value.replace(/,/g, '');
        }
        const parsed = parseFloat(value);
        return isNaN(parsed) ? null : parsed;
    };

    const handleSaveChanges = () => {
        const dataToSave = localData.filter(row =>
            row.date && data.series_names.some(name => row[name] !== '')
        ).map(row => ({
            ...row,
            ...data.series_names.reduce((acc, name) => ({
                ...acc,
                [name]: row[name] === '' ? null : parseNumber(row[name])
            }), {})
        }));
        onDataChange(dataToSave);
        setEditMode(false);
        setSelectedRowKeys([]);
    };

    const isValidDate = (dateString) => {
        // 检查是否为Excel日期格式（数字）
        if (/^\d+$/.test(dateString)) {
            const excelEpoch = new Date(1899, 11, 30);
            const msPerDay = 24 * 60 * 60 * 1000;
            const excelDate = new Date(excelEpoch.getTime() + parseInt(dateString, 10) * msPerDay);
            return !isNaN(excelDate.getTime());
        }

        // 检查其他日期格式
        const formats = [
            'YYYY-MM-DD',
            'YYYY/MM/DD',
            'DD-MM-YYYY',
            'DD/MM/YYYY',
            'MM-DD-YYYY',
            'MM/DD/YYYY',
            'YYYY年MM月DD日',
            'YY-MM-DD',
            'YY/MM/DD'
        ];
        return formats.some(format => moment(dateString, format, true).isValid());
    };

    const formatDate = (dateString) => {
        // 处理Excel日期格式
        if (/^\d+$/.test(dateString)) {
            const excelEpoch = new Date(1899, 11, 30);
            const msPerDay = 24 * 60 * 60 * 1000;
            const excelDate = new Date(excelEpoch.getTime() + parseInt(dateString, 10) * msPerDay);
            return moment(excelDate).format('YYYY-MM-DD');
        }

        // 处理其他日期格式
        const formats = [
            'YYYY-MM-DD',
            'YYYY/MM/DD',
            'DD-MM-YYYY',
            'DD/MM/YYYY',
            'MM-DD-YYYY',
            'MM/DD/YYYY',
            'YYYY年MM月DD日',
            'YY-MM-DD',
            'YY/MM/DD'
        ];

        for (let format of formats) {
            const date = moment(dateString, format, true);
            if (date.isValid()) {
                return date.format('YYYY-MM-DD');
            }
        }

        // 如果所有格式都不匹配，返回原始字符串
        return dateString;
    };

    const handleImportExcel = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

            // 忽略表头，从第二行开始读取
            const newData = jsonData.slice(1)
                .filter(row => row.length >= 2 && isValidDate(row[0])) // 过滤掉不符合日期格式的行
                .map((row, index) => {
                    const [date, value] = row;
                    return {
                        key: localData.length + index,
                        date: formatDate(date),
                        [data.series_names[0]]: value // 假设只有一个时间序列
                    };
                });

            if (newData.length === 0) {
                message.warning('导入的数据中没有有效的日期格式行');
            } else {
                setLocalData(prevData => [...newData, ...prevData]);
                message.success(`成功导入 ${newData.length} 行数据`);
            }
        };
        reader.readAsBinaryString(file);

        return false; // 阻止自动上传
    };

    const handleDownloadTemplate = async () => {
        try {
            const response = await axios.get('/api/download-template', {
                responseType: 'blob', // 重要：设置响应类型为 blob
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data_template.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading template:', error);
            message.error('下载模板失败');
        }
    };

    return (
        <div>
            <Space style={{ marginBottom: 16 }}>
                <Button onClick={onFetchData} disabled={editMode}>
                    提取数据
                </Button>
                <Button
                    type={editMode ? "primary" : "default"}
                    onClick={() => {
                        if (editMode) {
                            handleSaveChanges();
                        } else {
                            setEditMode(true);
                        }
                    }}
                    disabled={!dataFetched}
                >
                    {editMode ? '保存并退出编辑模式' : '编辑数据'}
                </Button>
                {editMode && dataFetched && (
                    <>
                        <Upload
                            beforeUpload={handleImportExcel}
                            accept=".xlsx,.xls,.csv"
                            showUploadList={false}
                        >
                            <Button>导入Excel表</Button>
                        </Upload>
                        <Button onClick={handleDownloadTemplate}>
                            下载Excel模板
                        </Button>
                        <Button onClick={handleInsertRow}>
                            插入行
                        </Button>
                        <Button onClick={handleDeleteSelectedRows} disabled={selectedRowKeys.length === 0}>
                            删除所选行
                        </Button>
                        <Button onClick={handleUndoChanges}>
                            撤销所有变更
                        </Button>
                    </>
                )}
            </Space>
            {dataFetched ? (
                <Table
                    columns={columns}
                    dataSource={localData}
                    rowSelection={editMode ? {
                        type: 'checkbox',
                        selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    } : undefined}
                />
            ) : (
                <div>请选择时间序列并提取数据</div>
            )}
        </div>
    );
};

export default DataTable;
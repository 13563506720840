import React, { useState, useEffect, useCallback } from 'react';
import { Tree, Menu, Modal, Form, Input, message, Button } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  fetchLabels,
  createLabel,
  updateLabel,
  deleteLabel,
  createTimeSeries,
  deleteTimeSeries,
  updateTimeSeries
} from '../services/api';
import { generateUniqueId } from '../utils/uuidGenerator';

const { confirm } = Modal;

const LabelTree = ({ onSelectTimeSeries, onDeleteTimeSeries }) => {
  const [labels, setLabels] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState({ type: '', node: null });
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [form] = Form.useForm();

  const fetchLabelsData = useCallback(async () => {
    try {
      const data = await fetchLabels();
      setLabels(data);
    } catch (error) {
      console.error('Error fetching labels:', error);
      message.error('获取标签失败');
    }
  }, []);

  useEffect(() => {
    fetchLabelsData();
  }, [fetchLabelsData]);

  const getAllTimeSeriesIds = useCallback((node) => {
    const ids = [];
    const traverse = (node) => {
      if (node.isLeaf) {
        ids.push(parseInt(node.key.split('-')[1]));
      } else if (node.children) {
        node.children.forEach(traverse);
      }
    };
    traverse(node);
    return ids;
  }, []);

  const handleDelete = useCallback(async (node, force = false) => {
    if (!node) {
      console.error('No node selected for deletion');
      message.error('删除失败：未选择节点');
      return;
    }

    try {
      const nodeId = node.key.split('-')[1];
      if (node.isLeaf) {
        await deleteTimeSeries(nodeId);
        onDeleteTimeSeries([parseInt(nodeId)]);
        message.success('删除成功');
        fetchLabelsData();
      } else {
        const response = await deleteLabel(nodeId, force);
        if (response.timeseries_with_data && !force) {
          // 如果存在数据且不是强制删除，显示二次确认对话框
          confirm({
            title: '警告：标签下的时间序列包含数据',
            icon: <ExclamationCircleOutlined />,
            content: (
              <div>
                <p>以下时间序列包含数据：</p>
                <ul>
                  {response.timeseries_with_data.map(([name, count]) => (
                    <li key={name}>{name}: {count} 条数据</li>
                  ))}
                </ul>
                <p>确定要删除这些数据吗？此操作不可逆。</p>
              </div>
            ),
            okText: '确定删除',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              handleDelete(node, true);
            },
          });
        } else {
          message.success('删除成功');
          // 获取被删除的时间序列 ID 列表
          const deletedTimeSeriesIds = getAllTimeSeriesIds(node);
          onDeleteTimeSeries(deletedTimeSeriesIds);
          fetchLabelsData();
        }
      }
    } catch (error) {
      console.error('Delete error:', error);
      message.error('删除失败：' + (error.message || '未知错误'));
    }
  }, [fetchLabelsData, onDeleteTimeSeries, getAllTimeSeriesIds]);

  const showDeleteConfirm = useCallback((node) => {
    if (!node) {
      console.error('No node selected for deletion');
      message.error('删除失败：未选择节点');
      return;
    }

    confirm({
      title: '确定要删除这个标签吗？',
      icon: <ExclamationCircleOutlined />,
      content: '删除后将无法恢复，包括其所有子标签和关联的时间序列。',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        handleDelete(node);
      },
    });
  }, [handleDelete]);

  const handleMenuClick = useCallback(({ key }) => {
    const node = contextMenu.node;
    setContextMenu(null);
    if (key === 'delete') {
      showDeleteConfirm(node);
    } else {
      setModalAction({ type: key, node: node });
      setModalVisible(true);
    }
  }, [contextMenu, showDeleteConfirm]);

  const handleRightClick = useCallback(({ event, node }) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      node: node,
    });
  }, []);

  const handleModalOk = useCallback(async () => {
    try {
      const values = await form.validateFields();
      if (modalAction.type === 'add_root') {
        await createLabel({ label_name: values.label_name });
      } else if (modalAction.type === 'add_child') {
        if (!modalAction.node) {
          throw new Error('No node selected for adding child');
        }
        await createLabel({ label_name: values.label_name, parent_id: modalAction.node.key.split('-')[1] });
      } else if (modalAction.type === 'rename') {
        if (!modalAction.node) {
          throw new Error('No node selected for renaming');
        }
        const nodeId = modalAction.node.key.split('-')[1];
        if (modalAction.node.isLeaf) {
          const newIndicatorId = generateUniqueId(values.label_name);
          await updateTimeSeries(nodeId, { series_name: values.label_name, indicator_id: newIndicatorId });
        } else {
          await updateLabel(nodeId, { label_name: values.label_name });
        }
      } else if (modalAction.type === 'add_timeseries') {
        if (!modalAction.node) {
          throw new Error('No node selected for adding time series');
        }
        const indicatorId = generateUniqueId(values.label_name);
        await createTimeSeries({
          series_name: values.label_name,
          label_id: modalAction.node.key.split('-')[1],
          indicator_id: indicatorId,
          frequency: values.frequency,
          unit: values.unit
        });
      }
      setModalVisible(false);
      form.resetFields();
      fetchLabelsData();
      message.success('操作成功');
    } catch (error) {
      console.error('Modal operation error:', error);
      message.error('操作失败：' + (error.message || '未知错误'));
    }
  }, [modalAction, form, fetchLabelsData]);

  const renderTreeNodes = useCallback((data) => {
    return data.map((item) => ({
      key: item.is_timeseries ? `ts-${item.series_id}` : `label-${item.label_id}`,
      title: item.is_timeseries ? item.series_name : item.label_name,
      children: item.children && item.children.length > 0 ? renderTreeNodes(item.children) : undefined,
      checkable: item.is_timeseries,
      selectable: item.is_timeseries,
      isLeaf: item.is_timeseries,
    }));
  }, []);

  const onExpand = useCallback((expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  }, []);

  const onCheck = useCallback((checkedKeysValue, info) => {
    setCheckedKeys(checkedKeysValue);
    const timeSeriesIds = checkedKeysValue
      .filter(key => key.startsWith('ts-'))
      .map(key => parseInt(key.split('-')[1]));
    onSelectTimeSeries(timeSeriesIds);
  }, [onSelectTimeSeries]);

  const treeData = renderTreeNodes(labels);

  // 自定义右键菜单样式
  const menuStyle = {
    boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    background: '#fff',
    borderRadius: '2px',
    border: '1px solid #f0f0f0',
  };

  // 自定义菜单项样式
  const menuItemStyle = {
    padding: '5px 12px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.85)',
    textAlign: 'left',
    cursor: 'pointer',
    transition: 'all 0.3s',
  };

  return (
    <div onClick={() => setContextMenu(null)}>
      <Button
        icon={<PlusOutlined />}
        onClick={() => {
          setModalAction({ type: 'add_root', node: null });
          setModalVisible(true);
        }}
        style={{ marginBottom: 16 }}
      >
        创建一级标签
      </Button>
      <Tree
        checkable
        onRightClick={handleRightClick}
        treeData={treeData}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
      />
      {contextMenu && (
        <Menu
          onClick={handleMenuClick}
          style={{
            ...menuStyle,
            position: 'fixed',
            zIndex: 1000,
            left: contextMenu.x,
            top: contextMenu.y,
          }}
        >
          {!contextMenu.node.isLeaf && <Menu.Item key="add_child" style={menuItemStyle}>增加下一级子标签</Menu.Item>}
          {!contextMenu.node.isLeaf && <Menu.Item key="add_timeseries" style={menuItemStyle}>增加时间序列数据点</Menu.Item>}
          <Menu.Item key="rename" style={menuItemStyle}>重命名</Menu.Item>
          <Menu.Item key="delete" style={menuItemStyle}>删除</Menu.Item>
        </Menu>
      )}
      <Modal
        title={
          modalAction.type === 'add_root' ? '创建一级标签' :
            modalAction.type === 'add_child' ? '增加下一级子标签' :
              modalAction.type === 'rename' ? '重命名' :
                modalAction.type === 'add_timeseries' ? '增加时间序列数据点' :
                  ''
        }
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={() => setModalVisible(false)}
      >
        <Form form={form}>
          <Form.Item name="label_name" label="名称" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {modalAction.type === 'add_timeseries' && (
            <>
              <Form.Item name="frequency" label="频率" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="unit" label="单位" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default LabelTree;